<template>
	<div class="max-size">
		<v-container class="max-size pa-0">
			<div
				:style="cropperStyle(true)"
				v-if="!image"
				@click="chooseImage"
				class="text-center text-h6 max-size d-flex justify-center align-center"
			>
				<svg-icon icon="camera" />
			</div>
			<div
				v-else
				class="max-size"
				:style="cropperStyle(false)"
				v-on:dblclick="chooseImage"
			>
				<Cropper

					class="max-size"
					:src="image"
					ref="imageCropper"
					@change="onChange"
					:stencil-props="stencilProps"
					:stencil-component="useCircleStencil ? `circle-stencil` : `rectangle-stencil`"
					image-restriction="stencil"
					:default-size="defaultStencilSize"
					:default-visible-area="defaultVisibleArea"
					defaultBoundaries="fill"
				/>
			</div>
		</v-container>

		<input class="d-none" type="file" ref="file" @change="loadImage($event)" :accept="acceptTypes">
	</div>
</template>

<script>
import { Cropper } from "vue-advanced-cropper";

export default {
	components: {
		Cropper
	},
	computed: {
		acceptTypes() {
			return this.allowGif ? `.png, .jpg, .jpeg, .gif` : `.png, .jpg, .jpeg`
		},
		getHandlers() {
			if (this.imageType === `image/gif`) return {}

			return this.scalable
				? { eastNorth: true, north: true, westNorth: true, west: true, westSouth: true, south: true, eastSouth: true, east: true }
				: {}
		},
		getAspectRatio() {
			if (this.imageType === `image/gif`) return null
			if (this.mode === `background`) return 367/130
			if (this.mode === `messageCard`) return 460/120
			if (this.mode === `messageModal`) return null
			return this.scalable
				? null
				: 1
		},
		stencilProps() {
			return {
				handlers: this.getHandlers,
				movable: this.imageType === `image/gif` ? false : this.movable,
				scalable: this.imageType === `image/gif` ? false : this.scalable,
				aspectRatio: this.getAspectRatio,
				lines: {
					north: false,
					west: false,
					south: false,
					east: false,
				},
			}
		}
	},
	data() {
		return {
			result: null,
			image: null,
			imageType: null,
		};
	},
	name: "ComponentImage",
	methods: {
		cropperStyle(template) {
			let style = `height: 100%; width: 100%; overflow:hidden; border: solid 1px #888;`
			if (this.mode === `profile`) style += ` border-radius: 50%;`
			if (template) style += ` ` + (this.editable && ` cursor: pointer;`)
			return style
		},
		defaultStencilSize({ imageSize, visibleArea }) {
			return {
				width: (visibleArea || imageSize).width,
				height: (visibleArea || imageSize).height,
			};
		},
		defaultVisibleArea({ imageSize }) {
			if (this.mode !== `profile`) return {
				width: imageSize.width,
				height: imageSize.height,
				left: 0,
				top: 0,
			}
			return {
				width: Math.min(imageSize.width, imageSize.height),
				height: Math.min(imageSize.width, imageSize.height),
				left: 0,
				top: 0,
			};
		},
		exists() {
			return this.image !== null
		},
		chooseImage() {
			this.editable && this.$refs.file.click()
		},
		onChange({coordinates, canvas}) {
			this.coordinates = coordinates
			this.result = canvas.toDataURL()
		},
		uploadImage() {
			return new Promise((resolve, reject) => {
				if (!this.image) reject()

				if (this.imageType === `image/gif`) {
					this.$uploadGif(this.$refs.file.files[0])
						.then(res => resolve(res))
						.catch(err => reject(err))
				} else {
					const vueImage = this.$refs.imageCropper
					this.$uploadImage(vueImage)
						.then(res => resolve(res))
						.catch(err => reject(err))
				}
			})
		},
		reset() {
			Object.assign(this.$data, this.$options.data.apply(this))
		},
		loadImage(event) {
			let input = event.target
			if (input.files && input.files[0]) {
				const file = input.files[0];
				this.imageType = file.type

				if (this.imageType === `image/gif`) {
					let reader = new FileReader()
					reader.onload = e => {
						this.image = e.target.result
					}
					reader.readAsDataURL(file)
				} else {
					let reader = new FileReader()
					reader.onload = e => {
						this.image = e.target.result
					}
					reader.readAsDataURL(file)
				}
			}
		}
	},
	props: {
		useCircleStencil: {
			type: Boolean,
			default: false,
		},
		editable: {
			type: Boolean,
			default: true,
		},
		allowGif: {
			type: Boolean,
			default: false,
		},
		movable: {
			type: Boolean,
			default: false,
		},
		scalable: {
			type: Boolean,
			default: false,
		},
		mode: {
			type: String,
			default: ``
		}
	},
};
</script>

<style>
.max-size {
	height: 100%;
	width: 100%;
}
</style>
